import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

document.body.style = 'background: #1C0533;';


ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
)
