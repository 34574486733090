import React, {useState, useEffect} from 'react'
import {Form, Input, Grid, Dropdown, GridColumn} from 'semantic-ui-react'
import {TxButton} from './substrate-lib/components'
import {useSubstrateState} from './substrate-lib'

const {u8aToHex} = require('@polkadot/util');

const PAS = 10000000000;
export default function Main(props) {
    const [status, setStatus] = useState({status: "", message: ""})
    const [formState, setFormState] = useState({addressTo: '', amount: 0, source: "Paseo"})
    const [formErrors, setFormErrors] = useState({addressTo: "", amount: ""})

    const [balances, setBalances] = useState({})
    const {api, keyring, currentAccount} = useSubstrateState()

    useEffect(() => {
        const addresses = keyring.getPairs().map(account => account.address)
        let unsubscribeAll = null

        api.query.system.account
            .multi(addresses, balances => {
                const balancesMap = addresses.reduce(
                    (acc, address, index) => ({
                        ...acc,
                        [address]: parseFloat(balances[index].data.free),
                    }),
                    {}
                )
                setBalances(balancesMap)
            })
            .then(unsub => {
                unsubscribeAll = unsub
            })
            .catch(console.error)

        return () => unsubscribeAll && unsubscribeAll()
    }, [api, keyring, setBalances])

    const onChange = (_, data) => {
        setFormState(prev => ({...prev, [data.state]: data.value}))
    }

    const sufficientBalance = (num) => {
        return balances[currentAccount.address] > num * PAS
    }

    const {addressTo, amount, source} = formState

    const accounts = keyring.getPairs()

    const availableAccounts = []
    accounts.map(account => {
        return availableAccounts.push({
            key: account.meta.name,
            text: account.meta.name,
            value: u8aToHex(account.publicKey),
        })
    })

    const relayChains = [
        {name: "Paseo", token: "PAS", url: "ws://localhost:8833"},
        // {name: "Rococo", token: "ROC", url: "ws://localhost:8833"},
    ];

    const availableRelayChains = []
    relayChains.map(relayChain => {
        return availableRelayChains.push({
            key: relayChain.name,
            text: relayChain.name,
            value: relayChain.name,
        })
    })


    const getReserveTransferParams = (to, amount) => {
        const parachainId = 4001;
        const dest = {
            V3: {
                parents: '0',
                interior: {
                    X1: {Parachain: parachainId},
                },
            },
        };

        const beneficiary = {
            V3: {
                parents: '0',
                interior: {
                    X1: {
                        AccountId32: {
                            network: null,
                            id: to,
                        },
                    },
                },
            },
        };

        const assets = {
            V3: [
                {
                    id: {
                        Concrete: {
                            parents: 0,
                            interior: {
                                Here: '',
                            },
                        },
                    },
                    fun: {
                        Fungible: amount,
                    },
                },
            ],
        };

        const fee_asset_item = '0';
        const weight_limit = 'Unlimited';

        return [dest, beneficiary, assets, fee_asset_item, weight_limit]
    }

    const Status = ({status}) => {
        switch (status.status) {
            case "success":
                console.log("tx-hash")
                console.log(status.message)
                return <div>Success: <a href={`https://paseo.subscan.io/extrinsic/${status.message}`}
                                        target="_blank">Subscan</a></div>
            case "ongoing":
                return <div>{status.message}</div>
            case "error":
                return <div>{status.message}</div>
            default:
                return <div></div>
        }
    }

    return (
        <div>
            <Grid.Column width={8} style={{textAlign: "left"}}>
                <h1 style={{textAlign: "center"}}>Onboard PAS to Pop Network</h1>
                <br/>
                <Form>
                    <Form.Field>
                        <label className={"label"}>Source Network</label>
                        <Dropdown
                            // placeholder="Choose the Source Network"
                            fluid
                            selection
                            search
                            options={availableRelayChains}
                            value={source}
                            state="source"
                            onChange={onChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label className={"label"}>Receiving Account</label>
                        <Dropdown
                            placeholder="Select from available addresses"
                            fluid
                            selection
                            search
                            required
                            options={availableAccounts}
                            state="addressTo"
                            onChange={onChange}
                        />
                    </Form.Field>
                    <span style={{color: "red"}}>{formErrors.addressTo}</span>
                    <Form.Field>
                        <label className={"label"}>Amount To Send</label>
                        <Input
                            fluid
                            type="number"
                            label="PAS"
                            labelPosition='right'
                            placeholder="0"
                            min="0"
                            required
                            state="amount"
                            onChange={(e, data) => {
                                if (data.value < 0) {
                                    setFormErrors({...formErrors, amount: "Can not be negative"})
                                } else if (!sufficientBalance(data.value)) {
                                    setFormErrors({
                                        ...formErrors,
                                        amount: "Selected account (from wallet) has insufficient balance"
                                    })
                                } else {
                                    setFormErrors({...formErrors, amount: ""})
                                }
                                onChange(e, data);
                            }}
                        />
                    </Form.Field>
                    <span style={{color: "red", marginBottom: "20px", fontSize: "20px"}}>{formErrors.amount}</span>
                    <Form.Field>
                        <label className={"label"}>Destination Public Address</label>
                        <Input
                            fluid
                            type="text"
                            placeholder="address"
                            disabled
                            value={addressTo}
                            state="addressTo"
                            onChange={onChange}
                        />
                    </Form.Field>
                    <Form.Field style={{textAlign: 'center'}}>
                        <TxButton
                            label="Submit"
                            disabled={!(formState.amount != 0 && formState.addressTo !== "" && formErrors.amount === "" && formErrors.addressTo === "")}
                            type="SIGNED-TX"
                            setStatus={setStatus}
                            attrs={{
                                palletRpc: 'xcmPallet',
                                callable: 'limitedReserveTransferAssets',
                                inputParams: getReserveTransferParams(addressTo, amount * PAS),
                                paramFields: [true, true, true, true, true],
                            }}
                        />
                    </Form.Field>
                    <div style={{
                        overflowWrap: 'break-word',
                        textAlign: "center"
                    }}><Status status={status}/></div>
                </Form>
                <br/>
            </Grid.Column>
            <GridColumn>
                <span>Need PAS? Use the <a href="https://faucet.polkadot.io/" target="_blank" rel="noreferrer">official faucet</a></span>
            </GridColumn>
        </div>
    )
}
