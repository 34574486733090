import React, {useEffect} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import {
    Menu,
    Button,
    Dropdown,
    Container,
    Image,
} from 'semantic-ui-react'

import {useSubstrate, useSubstrateState} from './substrate-lib'

const acctAddr = acct => (acct ? acct.address : '')

function Main(props) {
    const {
        setCurrentAccount,
        state: {keyring, currentAccount},
    } = useSubstrate()

    // Get the list of accounts we possess the private key for
    const keyringOptions = keyring.getPairs().map(account => ({
        key: account.address,
        value: account.address,
        text: account.meta.name.toUpperCase(),
        icon: 'user',
    }))

    const initialAddress =
        keyringOptions.length > 0 ? keyringOptions[0].value : ''

    // Set the initial address
    useEffect(() => {
        // `setCurrentAccount()` is called only when currentAccount is null (uninitialized)
        !currentAccount &&
        initialAddress.length > 0 &&
        setCurrentAccount(keyring.getPair(initialAddress))
    }, [currentAccount, setCurrentAccount, keyring, initialAddress])

    const onChange = addr => {
        setCurrentAccount(keyring.getPair(addr))
    }

    return (
        <Menu
            attached="top"
            tabular
            style={{
                paddingTop: '6em',
                paddingBottom: '5em',
                borderStyle: "none"
            }}
        >
            <Container>
                <Menu.Menu>
                    <Image
                        src={`${process.env.PUBLIC_URL}/assets/pop-avatar-circle.png`}
                        size="tiny"
                    />
                </Menu.Menu>
                <Menu.Menu position="right" style={{alignItems: 'center', color: "#fff"}}>
                    {!currentAccount ? (
                        <span>
                        </span>
                    ) : null}
                    <CopyToClipboard text={acctAddr(currentAccount)}>
                        <Button
                            basic
                            circular
                            size="large"
                            icon="user"
                            color={currentAccount ? 'green' : 'red'}
                        />
                    </CopyToClipboard>
                    <Dropdown
                        search
                        selection
                        clearable
                        placeholder={currentAccount ? "Select an account" : "No accounts connected"}
                        options={keyringOptions}
                        onChange={(_, dropdown) => {
                            onChange(dropdown.value)
                        }}
                        value={acctAddr(currentAccount)}
                    />
                </Menu.Menu>
            </Container>
        </Menu>
    )
}

export default function AccountSelector(props) {
    const {api, keyring} = useSubstrateState()
    return keyring.getPairs && api.query ? <Main {...props} /> : null
}
