import React, {createRef} from 'react'
import {
    Container,
    Dimmer,
    Loader,
    Grid,
    Sticky,
    Message,
} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import {SubstrateContextProvider, useSubstrateState} from './substrate-lib'
import {DeveloperConsole} from './substrate-lib/components'

import AccountSelector from './AccountSelector'
// import NodeInfo from './NodeInfo'
import ReserveTransfer from './ReserveTransfer'

function Main() {
    const {apiState, apiError, keyringState} = useSubstrateState()

    const loader = text => (
        <Dimmer active>
            <Loader size="small">{text}</Loader>
        </Dimmer>
    )

    const message = errObj => (
        <Grid centered columns={2} padded>
            <Grid.Column>
                <Message
                    negative
                    compact
                    floating
                    header="Error Connecting to Substrate"
                    content={`Connection to websocket '${errObj.target.url}' failed.`}
                />
            </Grid.Column>
        </Grid>
    )

    if (apiState === 'ERROR') return message(apiError)
    else if (apiState !== 'READY') return loader('Connecting...')

    if (keyringState !== 'READY') {
        return loader(
            "Loading accounts (please review any extension's authorization)"
        )
    }

    const contextRef = createRef()


    return (
        <div ref={contextRef}>
            <Sticky context={contextRef}>
                <AccountSelector/>
            </Sticky>
            <Container style={{backgroundColor: "#1C0533", color: "white"}}>

                {/*<Grid.Row stretched>*/}
                {/*  <NodeInfo />*/}
                {/*</Grid.Row>*/}
                <Grid centered className={"background"} style={{padding: "75px"}}>
                    <Grid.Column textAlign={"center"} width={8}>
                        <ReserveTransfer/>
                    </Grid.Column>
                </Grid>

            </Container>
            <DeveloperConsole/>
            <div style={{padding: "50px"}}></div>
        </div>
    )
}

export default function App() {
    return (
        <SubstrateContextProvider>
            <Main/>
        </SubstrateContextProvider>
    )
}
